export const circleNodeImage = (() => {
  const img = new Image()
  img.src = require('../../assets/images/profile_placeholder.png')
  return img
})()

export const makeCircleNodeImage = (photoUrl?: string) => {
  if (!photoUrl) return circleNodeImage
  else {
    const img = new Image()
    img.src = photoUrl
    return img
  }
}
