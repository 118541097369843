import { appReducer } from './reducers/app'
import { authenticationReducer } from './reducers/authentication'
import { circleReducer } from './reducers/circle'
import { entityReducer } from './reducers/entity'
import { meReducer } from './reducers/me'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk, { ThunkAction } from 'redux-thunk'

export type Action<T> = { type: T }

export type RootState = ReturnType<typeof rootReducer>
// @ts-ignore
export type Thunk<U = Action<any>, T = void> = ThunkAction<T, RootState, unknown, U>

export type AsyncThunk<U = Action<any>, ReturnType = void> = ThunkAction<
  Promise<ReturnType>,
  RootState,
  unknown,
  // @ts-ignore
  U
>
const rootReducer = combineReducers({
  app: appReducer,
  authentication: authenticationReducer,
  circle: circleReducer,
  entity: entityReducer,
  me: meReducer,
})

const useDevTools = true
let store = createStore(rootReducer, applyMiddleware(thunk))

if (process.env.NODE_ENV !== 'production' && useDevTools) {
  // @ts-ignore
  store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
}

export default store
