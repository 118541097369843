import { api } from '../api'
import { Circle } from '../types/circle.types'

export const circleService = {
  fetchCircle: async () =>
    api.prop<{ circle: Circle }>('circle', {
      request: {
        method: 'GET',
        endPoint: '/circle',
        parameters: {},
      },
    }),
}
