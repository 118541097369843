import { popLoading, pushError, pushLoading } from './app'
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthCredential,
  OAuthProvider,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  User as FirebaseUser,
  UserCredential,
} from 'firebase/auth'
import { t } from 'i18next'
import produce from 'immer'
import { Dispatch } from 'react'
import { mergeEntities } from 'src/redux/reducers/entity'
import { logout, setIdToken, setMe } from 'src/redux/reducers/me'
import { Action, AsyncThunk } from 'src/redux/store'
import { Error } from 'src/repository/Error'
import { userService } from 'src/repository/services/userService'
import { AuthUser, User } from 'src/repository/types'
import config from 'src/utils/config'

export enum AuthStep {
  continue,
  enterPassword,
  registerForm,
  directLogin,
  loggedIn,
}

type Setup = {
  id: string
  first_name: string
  last_name: string
  user_name: string
}

type State = {
  authStep: AuthStep
  authUser: AuthUser
  setup: Setup | null
}

type ActionType =
  | { type: 'authentication/setAuthStep'; step: AuthStep }
  | { type: 'authentication/setAuthUser'; authUser: Partial<AuthUser> }
  | { type: 'authentication/setSetup'; setup: Setup }
  | Action<'authentication/flush'>
  | Action<'me/logout'>

// MARK: - State

export const initialState: State = {
  authStep: AuthStep.continue,
  setup: null,
  authUser: {
    id: '',
    userName: '',
    firebaseToken: '',
    thirdPartyToken: { idToken: '', accessToken: '' },
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    imageUrl: '',
    isPublisher: false,
    authType: { type: 'email', email: '', password: '' },
    nonce: '',
  },
}

// MARK: - Reducer

export const authenticationReducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'authentication/setAuthStep':
      return produce(state, draft => {
        draft.authStep = action.step
        return draft
      })

    case 'authentication/setSetup':
      return produce(state, draft => {
        draft.setup = action.setup ?? null
        return draft
      })

    case 'authentication/setAuthUser':
      return produce(state, draft => {
        draft.authUser = { ...draft.authUser, ...action.authUser }
        return draft
      })

    case 'me/logout':
    case 'authentication/flush':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const setAuthUser = (authUser: Partial<AuthUser>): ActionType => ({
  type: 'authentication/setAuthUser',
  authUser: authUser,
})

export const fetchContinueStatus =
  (authUser: AuthUser, email?: string, phone?: string): AsyncThunk =>
  async dispatch => {
    if (!email && !phone) {
      dispatch(pushError(Error.displayable('Invalid input', 'Please fill all fields')))
      return
    }

    dispatch(pushLoading('authentication'))

    const response = await userService.fetchUserStatus(email, phone?.replaceAll(' ', ''))

    if (response.success) {
      const { continue: continueType, auth_types, setup } = response.value
      const shouldLogin = continueType === 'login'
      const authType = auth_types[0]?.toLowerCase() ?? 'email'
      if (setup) dispatch({ type: 'authentication/setSetup', setup })

      if (authType !== 'email' && authUser.authType.type === 'email') {
        dispatch(
          pushError(
            Error.displayable(t('accessError'), t('pleaseContinueWith', { type: authType })),
          ),
        )
      } else {
        dispatch(setAuthUser(authUser))

        if (phone && shouldLogin) dispatch(setAuthStep(AuthStep.directLogin))
        else if (authType === 'email' && shouldLogin) dispatch(setAuthStep(AuthStep.enterPassword))
        else if (authType && shouldLogin) dispatch(setAuthStep(AuthStep.directLogin))
        else dispatch(setAuthStep(AuthStep.registerForm))
      }
    } else {
      dispatch(pushError(response.error))
    }

    dispatch(popLoading('authentication'))
  }

export const registerByEmail =
  (user: AuthUser): AsyncThunk =>
  async dispatch => {
    if (user.authType.type !== 'email') return

    if (!user.email || !user.userName) {
      dispatch(pushError(Error.displayable('Invalid input', 'Please fill all fields')))
      return
    }

    dispatch(pushLoading('authentication'))

    try {
      let credential: UserCredential | undefined
      try {
        credential = await createUserWithEmailAndPassword(
          getAuth(),
          user.authType.email,
          user.authType.password,
        )
      } catch (error: any) {
        if (error?.message?.includes('email-already-in-use')) {
          const linkingResponse = await userService.linkUserEmail(
            user.authType.email,
            user.authType.password,
          )
          if (linkingResponse.success) {
            credential = await signInWithEmailAndPassword(
              getAuth(),
              user.authType.email,
              user.authType.password,
            )
          }
        } else {
          dispatch(pushError(error))
        }
      }

      if (credential) {
        const token = await storeToken(credential.user)
        const response = await createUser(user)

        if (response.success) {
          const { user: responseUser } = response.value
          dispatch(completeLogin(responseUser, token))
        } else {
          dispatch(pushError(response.error))
        }
      } else {
        dispatch(pushError(Error.accessDenied()))
      }
    } catch (error: any) {
      dispatch(pushError(error))
    }
    dispatch(popLoading('authentication'))
  }

export const registerByPhone =
  (user: AuthUser): AsyncThunk =>
  async dispatch => {
    if (user.authType.type !== 'phone') return

    if (!user.phoneNumber || !user.userName) {
      dispatch(pushError(Error.displayable('Invalid input', 'Please fill all fields')))
      return
    }

    dispatch(pushLoading('authentication'))

    try {
      const token = await getAuth().currentUser?.getIdToken()
      const response = await createUser(user)

      if (response.success && token) {
        const { user: responseUser } = response.value
        dispatch(completeLogin(responseUser, token))
      } else if (!response.success) {
        dispatch(pushError(response.error))
      } else {
        dispatch(pushError(Error.someThingWentWrong()))
      }
    } catch (error: any) {
      dispatch(pushError(Error.accessDenied()))
    }
    dispatch(popLoading('authentication'))
  }

export const registerThirdParty =
  (user: AuthUser): AsyncThunk =>
  async dispatch => {
    if (user.authType.type === 'email' || !user.email || !user.userName) {
      dispatch(pushError(Error.accessDenied()))
      return
    }
    dispatch(pushLoading('authentication'))

    const userNameCheckResponse = await userService.checkUsernameInUse(user.userName)

    if (!userNameCheckResponse.success || !userNameCheckResponse.value) {
      const message = 'Username is not available.'
      dispatch(pushError(Error.displayable('', message)))
      dispatch(popLoading('authentication'))
      return
    }

    const token = await storeToken(getAuth().currentUser)
    const response = await createUser(user)

    if (response.success) {
      const { user: responseUser } = response.value
      dispatch(completeLogin(responseUser, token))
    } else {
      dispatch(pushError(response.error))
      dispatch(popLoading('authentication'))
    }
  }

export const login =
  (email?: string, phone?: string): AsyncThunk =>
  async (dispatch, getState) => {
    email = email || getState().authentication.authUser.email
    dispatch(loginSilently(email, phone))
  }

export const loginSilently =
  (email?: string | null, phone?: string | null): AsyncThunk =>
  async dispatch => {
    dispatch(pushLoading('authentication'))

    const token = await storeToken(getAuth().currentUser)
    const response = await userService.fetchUserByEmail(
      email ?? undefined,
      phone?.replaceAll(' ', '') ?? undefined,
    )

    if (response.success) {
      const { user } = response.value
      dispatch(completeLogin(user, token))
    } else {
      dispatch(logout())
      dispatch(pushError(response.error))
      dispatch(popLoading('authentication'))
    }
  }

export const loginWithCustomToken =
  (customToken: string): AsyncThunk =>
  async dispatch => {
    dispatch(pushLoading('authentication'))

    const credential = await signInWithCustomToken(getAuth(), customToken)
    const token = await storeToken(credential.user)

    const response = await userService.fetchUserById(credential.user.uid)

    if (response.success) {
      const { user } = response.value
      dispatch(completeLogin(user, token))
    } else {
      dispatch(logout())
      dispatch(pushError(response.error))
      dispatch(popLoading('authentication'))
    }
  }

export const sendResetPasswordEmail =
  (email: string): AsyncThunk =>
  async dispatch => {
    dispatch(pushLoading('authentication'))

    const response = await userService.forgotPassword(email)

    if (response.success) {
    } else {
      dispatch(pushError(response.error))
    }
    dispatch(popLoading('authentication'))
  }

export const setAuthStep = (step: AuthStep): ActionType => ({
  type: 'authentication/setAuthStep',
  step: step,
})

export const clearAuthenticationFlow = (): ActionType => ({
  type: 'authentication/flush',
})

// MARK: - Selectors

export const getAuthStep = (state: State): AuthStep => {
  return state.authStep
}

export const getAuthUser = (state: State): AuthUser => {
  return state.authUser
}

export const getAuthSetup = (state: State): Setup | null => {
  return state.setup
}

// MARK: - Helpers

const createUser = async (user: AuthUser) => {
  return await userService.createUser({
    email: user.email || undefined,
    phone_number: user.phoneNumber?.replaceAll(' ', '') || undefined,
    first_name: user.firstName ?? '',
    last_name: user.lastName ?? '',
    user_name: user.userName ?? '',
    image_url: user.imageUrl ?? '',
    auth_types: [user.authType.type],
    notification_token: '',
  })
}

export const storeToken = async (user: FirebaseUser | null) => {
  if (!user) {
    throw Error.apiDisplayable({
      title: 'Token error',
      message: 'Firebase user not found',
      type: 1,
    })
  }

  const token = await user.getIdToken()
  localStorage.setItem(config.keys.firebaseToken, token)
  return token
}

export const getCredentials = async (dispatch: Dispatch<any>, user: AuthUser) => {
  let credential: UserCredential | null = null
  let authCredential: OAuthCredential | null = null

  try {
    switch (user.authType.type) {
      case 'apple':
        const provider = new OAuthProvider('apple.com')
        credential = await signInWithPopup(getAuth(), provider)
        authCredential = OAuthProvider.credentialFromResult(credential)
        break

      case 'google':
        const googleProvider = new GoogleAuthProvider()
        credential = await signInWithPopup(getAuth(), googleProvider)
        authCredential = GoogleAuthProvider.credentialFromResult(credential)
        break

      case 'facebook':
        const facebookProvider = new FacebookAuthProvider()
        credential = await signInWithPopup(getAuth(), facebookProvider)
        authCredential = FacebookAuthProvider.credentialFromResult(credential)
        break

      case 'email':
        if (user.authType.type !== 'email') {
          dispatch(pushError(Error.accessDenied()))
          return { token: undefined, updatedUser: undefined }
        }
        credential = await signInWithEmailAndPassword(
          getAuth(),
          user.authType.email,
          user.authType.password,
        )
    }
    if (!credential) {
      dispatch(pushError(Error.accessDenied()))
      return { token: undefined, updatedUser: undefined }
    }

    const firebaseUser = credential?.user
    const updatedUser = { ...user }
    const [firstName, ...lastNames] = firebaseUser.displayName?.split(' ') ?? []
    updatedUser.email = firebaseUser.email ?? ''
    updatedUser.firstName = firstName ?? ''
    updatedUser.lastName = lastNames?.join(' ') ?? ''
    updatedUser.imageUrl = firebaseUser.photoURL ?? ''
    updatedUser.thirdPartyToken = {
      idToken: authCredential?.idToken ?? '',
      accessToken: authCredential?.accessToken ?? '',
    }
    const token = await firebaseUser.getIdToken()

    return { token, updatedUser, credential, authCredential }
  } catch (error: any) {
    if (error?.message?.toString()?.includes('exists')) {
      const message = `Account not created with ${user.authType.type}. Please try again with another provider.`
      dispatch(pushError(Error.displayable('', message)))
    }
    if (error.message?.includes('invalid-email')) {
      dispatch(pushError(Error.displayable('Oopps!', 'invalidEmail')))
    }
    if (error.message?.includes('wrong-password')) {
      dispatch(pushError(Error.displayable('Oopps!', 'invalidPassword')))
    }
    if (error.message?.includes('too-many-requests')) {
      dispatch(pushError(Error.displayable('Oopps!', 'tooManyTrials')))
    }

    return { token: undefined, updatedUser: undefined }
  }
}

const completeLogin =
  (user: User, token: string): AsyncThunk =>
  async dispatch => {
    dispatch(setIdToken(token))
    dispatch(setMe(user))
    dispatch(mergeEntities({ user: [user] }))

    dispatch(setAuthStep(AuthStep.loggedIn))
    dispatch(clearAuthenticationFlow())
    dispatch(popLoading('authentication'))
  }
