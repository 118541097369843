import produce from 'immer'
import { DeleteAction, MergeAction } from 'src/redux/reducers/entity'
import { Action, Thunk } from 'src/redux/store'
import { circleService } from 'src/repository/services/circleService'
import { CircleLink, CircleNode } from 'src/repository/types/circle.types'

type State = {
  nodes: CircleNode[]
  links: CircleLink[]
}

type ActionType =
  | { type: 'circle/setCircle'; nodes: CircleNode[]; links: CircleLink[] }
  | MergeAction
  | DeleteAction
  | Action<'me/logout'>

// MARK: - State

export const initialState: State = {
  nodes: [],
  links: [],
}

// MARK: - Reducer

export const circleReducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'circle/setCircle':
      return produce(state, draft => {
        draft.nodes = action.nodes
        draft.links = action.links
        return draft
      })

    case 'me/logout':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const fetchCircle = (): Thunk<ActionType> => dispatch => {
  circleService
    .fetchCircle()
    .done(circle =>
      dispatch({ type: 'circle/setCircle', nodes: circle.nodes, links: circle.links }),
    )
}

// MARK: - Selectors

export const getCircleData = (state: State): { nodes: CircleNode[]; links: CircleLink[] } => {
  return { nodes: state.nodes, links: state.links }
}
