import { Route, Switch } from 'react-router-dom'
import { Circle } from 'src/features/circle'
import { Home } from 'src/features/home'

export const Routes = () => (
  <Switch>
    <Route exact path="/" render={() => <Home />} />

    <Route
      exact
      path="/circle"
      render={({ location }) => {
        const params = new URLSearchParams(location.search)
        const userId = params.get('user_id')
        const customToken = params.get('custom_token')
        return <Circle userId={userId} customToken={customToken} />
      }}
    />
  </Switch>
)
