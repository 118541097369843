import { PageLoader } from './features/common/PageLoader'
import config from './utils/config'
import { getApps, initializeApp } from 'firebase/app'
import React, { useEffect, useState } from 'react'

export const AppInitializationProvider: React.FC<{}> = ({ children }) => {
  // MARK: - Hooks

  const [ready, setReady] = useState(false)

  // MARK: - Effects

  useEffect(() => {
    // Initialize Firebase
    if (!getApps().length) initializeApp(config.firebaseConfig)

    // Initialize Segment
    // const loadAnalytics = async () => {
    //   const [response] = await AnalyticsBrowser.load({ writeKey: config.segment_write_key })
    //   window.analytics = response as any
    //   setReady(true)
    // }
    // loadAnalytics()
    setReady(true)
  }, [])

  // MARK: - Render

  return <>{ready ? children : <PageLoader />}</>
}
