import config from './utils/config'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react'

// @ts-ignore
let ErrorBoundary: React.FC<{ children }> = ({ children }) => <>{children}</>

if (window.location.hostname !== 'localhost') {
  Bugsnag.start({
    apiKey: config.bugsnag_api_key,
    plugins: [new BugsnagPluginReact()],
  })
  // @ts-ignore
  ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React)
}

export default ErrorBoundary
