type Config = {
  keys: {
    firebaseToken: string
    firebaseWebToken: string
    livePreview: string
    localization: string
    theme: string
  }
  sizes: {
    thumbnailImage: number
    mainImage: number
    webBannerImage: number
  }
  envName: string
  title: string
  appUrl: string
  platformUrl: string
  apiUrl: string
  google_maps_api_key: string
  icons_8_api_key: string
  flat_icon_api_key: string
  bugsnag_api_key: string
  segment_write_key: string

  firebaseConfig: {
    apiKey: string
    authDomain: string
    databaseURL: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
    appId: string
    measurementId: string
  }
}

const local: Config = {
  keys: {
    firebaseToken: 'INNER_FIREBASE_TOKEN_KEY',
    firebaseWebToken:
      'BJp2tOwA7ZHj_H3DtAxlLUokMDzb0FLr4O1GrIl9vrosZSSeM7IGxqn1ICpxGNEXXY4Hc3ny1cTcia_NwWw19PA',
    livePreview: 'LivePreview',
    localization: 'INNER_LOCALIZATION_KEY',
    theme: 'INNER_THEME_KEY',
  },
  sizes: {
    thumbnailImage: 400,
    mainImage: 1600,
    webBannerImage: 1200,
  },
  envName: 'local',
  title: 'INNER - Local',
  appUrl: 'http://localhost:3001',
  platformUrl: 'http://localhost:3000',
  apiUrl: 'https://api-po57iig42a-uc.a.run.app/api',
  google_maps_api_key: 'AIzaSyBziSbMtYGji3Ro06Zu3-5XamMJohwGmWQ',
  icons_8_api_key: 'JuU7UUdazTMpWmxFf4ZLY40hyJwbLGPqh8d8vn0S',
  bugsnag_api_key: 'f3998715099246ffe3d6f147d1c6bbe7',
  flat_icon_api_key: '2123857d7fd15ab47391ee9cf8c880dccdc01277',
  segment_write_key: 'AIt9tYYN8y7T2AcEHk3NaOLxuOHfdG8b',

  firebaseConfig: {
    apiKey: 'AIzaSyCakuLB4W5hFOyAj_N4byktFiL7Mn_dkQ0',
    authDomain: 'inner-development.firebaseapp.com',
    databaseURL: 'https://inner-development-default-rtdb.firebaseio.com',
    projectId: 'inner-development',
    storageBucket: 'inner-development.appspot.com',
    messagingSenderId: '34973609057',
    appId: '1:34973609057:web:e8df3583d6ae0e841d8e20',
    measurementId: 'G-EHEKDCZCRR',
  },
}

const dev: Config = {
  keys: {
    firebaseToken: 'INNER_FIREBASE_TOKEN_KEY',
    firebaseWebToken:
      'BJp2tOwA7ZHj_H3DtAxlLUokMDzb0FLr4O1GrIl9vrosZSSeM7IGxqn1ICpxGNEXXY4Hc3ny1cTcia_NwWw19PA-33RSwa89AAfkY',
    livePreview: 'LivePreview',
    localization: 'INNER_LOCALIZATION_KEY',
    theme: 'INNER_THEME_KEY',
  },
  sizes: {
    thumbnailImage: 400,
    mainImage: 1600,
    webBannerImage: 1200,
  },
  envName: 'development',
  title: 'Inner - Development',
  appUrl: 'https://development.app.innr.me',
  platformUrl: 'https://development.innr.me',
  apiUrl: 'https://api-po57iig42a-uc.a.run.app/api',
  google_maps_api_key: 'AIzaSyBziSbMtYGji3Ro06Zu3-5XamMJohwGmWQ',
  icons_8_api_key: 'JuU7UUdazTMpWmxFf4ZLY40hyJwbLGPqh8d8vn0S',
  flat_icon_api_key: '2123857d7fd15ab47391ee9cf8c880dccdc01277',
  bugsnag_api_key: 'f3998715099246ffe3d6f147d1c6bbe7',
  segment_write_key: 'AIt9tYYN8y7T2AcEHk3NaOLxuOHfdG8b',

  firebaseConfig: {
    apiKey: 'AIzaSyCakuLB4W5hFOyAj_N4byktFiL7Mn_dkQ0',
    authDomain: 'inner-development.firebaseapp.com',
    databaseURL: 'https://inner-development-default-rtdb.firebaseio.com',
    projectId: 'inner-development',
    storageBucket: 'inner-development.appspot.com',
    messagingSenderId: '34973609057',
    appId: '1:34973609057:web:e8df3583d6ae0e841d8e20',
    measurementId: 'G-EHEKDCZCRR',
  },
}

const prod: Config = {
  keys: {
    firebaseToken: 'INNER_FIREBASE_TOKEN_KEY',
    firebaseWebToken:
      'BMi-J3GaEGIbGbWOCAUMhkVwXS4H063zW4xLZzAsh3SjF873fjxYPEJKeBjeyDR15_Ksz0Pxf9yJiZqsi31tZ_Y',
    livePreview: 'LivePreview',
    localization: 'INNER_LOCALIZATION_KEY',
    theme: 'INNER_THEME_KEY',
  },
  sizes: {
    thumbnailImage: 400,
    mainImage: 1600,
    webBannerImage: 1200,
  },
  envName: 'production',
  title: 'Inner',
  appUrl: 'https://app.innr.me',
  platformUrl: 'https://innr.me',
  apiUrl: 'https://api-mvnsefmyja-uc.a.run.app/api',
  google_maps_api_key: 'AIzaSyBziSbMtYGji3Ro06Zu3-5XamMJohwGmWQ',
  icons_8_api_key: 'JuU7UUdazTMpWmxFf4ZLY40hyJwbLGPqh8d8vn0S',
  flat_icon_api_key: '2123857d7fd15ab47391ee9cf8c880dccdc01277',
  bugsnag_api_key: 'b34e225e19a9311745afd0abb94334c5',
  segment_write_key: 'RjHkzd8XP99GEe1BeEOQOH4qqqfNLldD',

  firebaseConfig: {
    apiKey: 'AIzaSyClMbFogLKQ85H-mM257skvgq85X0IKYtU',
    authDomain: 'inner-production.firebaseapp.com',
    databaseURL: 'https://inner-production-default-rtdb.firebaseio.com',
    projectId: 'inner-production',
    storageBucket: 'inner-production.appspot.com',
    messagingSenderId: '919148249008',
    appId: '1:919148249008:web:08637864b661d38490e075',
    measurementId: 'G-N644Y2CXB3',
  },
}

console.log(window.location.origin)
const currentConfig = () => {
  if (window.location.origin === prod.appUrl) {
    return prod
  } else if (window.location.origin === dev.appUrl) {
    return dev
  } else {
    return dev ?? local
  }
}

export default currentConfig()
