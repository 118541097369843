import produce from 'immer'
import { compact, head } from 'lodash'
import { User } from 'src/repository/types'
import { ID, Identifiable } from 'src/utils/helpers/mapId'

type Entity = Identifiable & User

type State = {
  user: Record<ID, User>
}

type Entities = Partial<{
  user: User[]
}>

const initialState: State = {
  user: {},
}

// MARK: - Reducer

export const entityReducer = (
  state = initialState,
  action: MergeAction | DeleteAction | ReplaceAction | { type: 'me/logout' },
): State => {
  switch (action.type) {
    case 'entity/merge':
      return produce(state, draft => {
        for (const entry of Object.entries(action.entities)) {
          if (!entry[0] || !entry[1]?.length) continue

          const entityType = entry[0] as keyof State
          const entities = entry[1] as Identifiable[]
          const entityRecord = compact(entities).reduce(
            (acc, element) => ({ ...acc, [element.id]: element }),
            {},
          )
          draft[entityType] = { ...draft[entityType], ...entityRecord }
        }
        return draft
      })

    case 'entity/delete':
      return produce(state, draft => {
        for (const [key, entityIds] of Object.entries(action.entities)) {
          for (const entityId of entityIds) delete draft[key as keyof State][entityId]
        }
        return draft
      })

    case 'entity/replace':
      return produce(state, draft => {
        for (const entry of Object.entries(action.entities)) {
          const entityType = entry[0] as keyof State
          const entities = entry[1] as Identifiable[]

          if (action.replaceIf) {
            for (const existingEntry of Object.entries(draft[entityType] ?? {})) {
              const [key, item] = existingEntry
              if (action.replaceIf(item)) delete draft[entityType][key]
            }
            const entityRecord = entities.reduce(
              (acc, element) => ({ ...acc, [element.id]: element }),
              {},
            )
            draft[entityType] = { ...draft[entityType], ...entityRecord }
          } else {
            const entityRecord = entities.reduce(
              (acc, element) => ({ ...acc, [element.id]: element }),
              {},
            )
            draft[entityType] = entityRecord
          }
        }
        return draft
      })

    case 'me/logout':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const mergeEntities = (entities: Entities): MergeAction => ({
  type: 'entity/merge',
  entities: entities,
})

export const deleteEntities = (entities: Partial<Record<keyof State, ID[]>>): DeleteAction => ({
  type: 'entity/delete',
  entities: entities,
})

export const replaceEntities = (
  entities: Entities,
  replaceIf?: (entity: any) => boolean,
): ReplaceAction => ({
  type: 'entity/replace',
  entities,
  replaceIf,
})

// MARK: - Selectors

export const getEntity = <T extends Entity>(
  state: State,
  entityType: keyof State,
  primaryId?: ID | null,
): T | null => {
  const entities = state[entityType] as Record<ID, T>
  return primaryId ? entities[primaryId] ?? null : null
}

export const getEntities = <T extends Entity>(
  state: State,
  entityType: keyof State,
  filter?: (entity: T) => boolean,
): T[] => {
  const entities = Object.values(state[entityType] ?? {}) as T[]
  return filter ? entities.filter(filter) : entities
}

export const getFirstEntity = <T extends Entity>(
  state: State,
  entityType: keyof State,
  filter: (entity: T) => boolean,
): T | null => {
  return head(getEntities(state, entityType, filter)) ?? null
}

// MARK: - Action Types

export type MergeAction = {
  type: 'entity/merge'
  entities: Entities
}

export type DeleteAction = {
  type: 'entity/delete'
  entities: Partial<Record<keyof State, ID[]>>
}

export type ReplaceAction = {
  type: 'entity/replace'
  entities: Entities
  replaceIf?: (entity: any) => boolean
}
