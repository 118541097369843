import { App } from './App.tsx'
import ErrorBoundary from './AppErrorBoundary'
import './assets/scss/style.scss'
import config from './utils/config'
import { isElectron } from './utils/isElectron'
import { createBrowserHistory, createHashHistory } from 'history'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { Router } from 'react-router-dom'
import smoothscroll from 'smoothscroll-polyfill'
import 'src/App.css'
import store from 'src/redux/store'
import 'src/utils/localization'
import 'src/utils/theme/globalTheme'
import 'src/utils/types/promise.d'

// Log app version
console.info(`${config.envName} - v${require('../package.json').version}`)
smoothscroll.polyfill()

export const history = isElectron() ? createHashHistory() : createBrowserHistory()

ReactDOM.render(
  <ErrorBoundary>
    <ReduxProvider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </ReduxProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
)
