import { PageLoader } from '../common/PageLoader'
import { map } from 'lodash'
import { memo, useCallback, useEffect, useRef } from 'react'
import Graph, { ForceGraphMethods } from 'react-force-graph-2d'
import { useDispatch } from 'react-redux'
import { loginWithCustomToken } from 'src/redux/reducers/authentication'
import { fetchCircle, getCircleData } from 'src/redux/reducers/circle'
import { getIsAuthenticated, getMeId } from 'src/redux/reducers/me'
import { CircleLink, CircleNode } from 'src/repository/types/circle.types'
import { makeCircleNodeImage } from 'src/utils/helpers/circleHelper'
import { alpha } from 'src/utils/helpers/colorAlpha'
import { useSelect } from 'src/utils/hooks/useSelect'
import { useTheme } from 'styled-components'

export const Circle = memo<{
  userId: string | null
  customToken: string | null
}>(({ userId, customToken }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const meUserId = useSelect(state => getMeId(state.me))

  const isAuthenticated = useSelect(state => getIsAuthenticated(state.me))

  const data = useSelect(state => {
    const circleData = getCircleData(state.circle)
    const nodes = circleData.nodes.map(node => ({
      ...node,
      user: null,
      user_id: node.user?.id,
      title: node.user?.first_name,
      img: makeCircleNodeImage(node.user?.photo_url),
    }))
    return { nodes, links: circleData.links.map(link => ({ ...link, curvature: 0.2 })) }
  })

  // MARK: - Effects

  useEffect(() => {
    if (customToken) dispatch(loginWithCustomToken(customToken))
  }, [customToken])

  useEffect(() => {
    if (userId === meUserId && isAuthenticated) dispatch(fetchCircle())
  }, [meUserId, isAuthenticated])

  // MARK: - Render

  return meUserId && data.nodes.length ? (
    <ForceGraph meUserId={meUserId} data={data} />
  ) : (
    <PageLoader />
  )
})

export const ForceGraph = memo<{
  meUserId: string
  data: { nodes: CircleNode[]; links: CircleLink[] }
}>(({ data, meUserId }) => {
  // MARK: - Hooks

  const { palette } = useTheme()
  const graph = useRef<ForceGraphMethods | null>(null)
  const isDragging = useRef(false)
  const isSelected = useRef(false)
  const interval = useRef<NodeJS.Timer | null>(null)
  const reactNative = (window as any).ReactNativeWebView

  // MARK: - Effects

  useEffect(() => {
    if (reactNative) window.document.body.style.userSelect = 'none'
    graph.current?.zoom(3.2)

    graph.current
      ?.d3Force('charge')
      ?.strength((node: CircleNode) => (node.user_id === meUserId ? -1000 : -200))

    graph.current?.d3Force('link')?.strength((link: CircleLink) =>
      // @ts-ignore
      link.target.user_id === meUserId || link.target.user_id === meUserId ? 2 : 0.8,
    )
  }, [])

  // MARK: - Handler

  const setLinkedNodesDragging = useCallback(
    (nodeId: string, dragging: boolean) => {
      const selectedLinks = data.links.filter(
        link =>
          (link.source as CircleNode).id === nodeId || (link.target as CircleNode).id === nodeId,
      )
      const nodeIds = new Set(
        map(selectedLinks, 'target.id').concat(map(selectedLinks, 'source.id')),
      )
      data.nodes
        .filter(({ id }) => nodeIds.has(id))
        .forEach((selectedNode: CircleNode) => {
          selectedNode.isDragging = dragging
        })
    },
    [data],
  )

  const handleNodeClick = useCallback(
    (node: CircleNode) => {
      reactNative?.postMessage(JSON.stringify({ type: 'node_clicked', node }))
      isSelected.current = true

      const links = data.links.filter(link => (link.source as CircleNode).id === node.id)
      for (const item of data.nodes) item.isSelected = false
      for (const link of links) {
        const source = link.source as CircleNode
        const target = link.target as CircleNode
        source.isSelected = true
        target.isSelected = true
      }

      if (interval.current) clearInterval(interval.current)
      interval.current = setInterval(
        () => links.forEach(link => graph.current?.emitParticle(link)),
        300,
      )
    },
    [data],
  )

  const handleBackgroundClick = useCallback(() => {
    reactNative?.postMessage(JSON.stringify({ type: 'background_clicked' }))
    isSelected.current = false
    if (interval.current) clearInterval(interval.current)
    for (const node of data.nodes) node.isSelected = false
  }, [data])

  const handleNodeDrag = useCallback(
    (node: CircleNode) => {
      if (!isDragging.current) {
        reactNative?.postMessage(JSON.stringify({ type: 'node_drag_started', node }))

        isSelected.current = false
        if (interval.current) clearInterval(interval.current)
        for (const element of data.nodes) element.isSelected = false

        node.isDragging = true
        node.isFocus = true
        isDragging.current = true
        setLinkedNodesDragging(node.id, true)
      }
    },
    [data],
  )

  const handleNodeDragEnd = useCallback(
    (node: CircleNode) => {
      if (isDragging.current) {
        reactNative?.postMessage(JSON.stringify({ type: 'node_drag_ended', node }))
        node.isDragging = false
        node.isFocus = false
        isDragging.current = false
        setLinkedNodesDragging(node.id, false)
      }
    },
    [data],
  )

  const handleEngineStop = useCallback(() => {
    reactNative?.postMessage(JSON.stringify({ type: 'engine_stopped' }))
  }, [])

  // MARK: - Render

  const renderLine = useCallback((link: any, ctx: CanvasRenderingContext2D, scale: number) => {
    const [sourceX, sourceY] = [link.source.x, link.source.y] as number[]
    const [targetX, targetY] = [link.target.x, link.target.y] as number[]

    ctx.save()
    ctx.beginPath()
    ctx.moveTo(sourceX, sourceY)
    ctx.lineTo(targetX, targetY)
    ctx.strokeStyle = (() => {
      if (link.target.isFocus || link.source.isFocus) return alpha(palette.orange, 0.72)
      else if (isDragging.current || isSelected.current)
        return alpha(palette.background.separator, 0.06)
      else return palette.background.tertiary
    })()
    ctx.lineWidth = 1 / Math.log2(Math.max(2, scale * 1.1))
    ctx.stroke()
    ctx.restore()
  }, [])

  const renderNode = useCallback(
    (node: CircleNode, ctx: CanvasRenderingContext2D, scale: number) => {
      const x = node.x as number
      const y = node.y as number
      const size = 5.4 / Math.max(0.5, Math.log10(scale))

      // Draw profile image
      ctx.save()
      ctx.beginPath()
      ctx.arc(x, y, size * 0.8, 0, 2 * Math.PI)
      ctx.closePath()
      ctx.clip()
      ctx.fillStyle = palette.background.tertiary
      ctx.fill()
      ctx.globalAlpha = (() => {
        let globalAlpha = 0.1
        if (node.isDragging || node.isFocus || node.isSelected) globalAlpha = 1
        if (!isDragging.current && !isSelected.current) globalAlpha = 1
        if (isDragging.current && !node.isDragging) globalAlpha = 0.1
        return globalAlpha
      })()
      ctx.drawImage(node.img, x - size * 0.8, y - size * 0.8, size * 1.6, size * 1.6)
      ctx.restore()

      // Draw border radius
      ctx.save()
      ctx.beginPath()
      ctx.lineWidth = 1 / Math.log2(Math.max(2, scale * 1.1))
      ctx.strokeStyle = (() => {
        let color = palette.background.secondary
        if (node.isSelected) color = palette.purple
        else if (node.isDragging) color = alpha(palette.orange, 0.72)
        return color
      })()
      ctx.arc(x, y, size * 0.8 + 0.5 / Math.log2(Math.max(2, scale * 1.1)), 0, 2 * Math.PI)
      ctx.stroke()
      ctx.closePath()
      ctx.restore()

      // Draw label
      ctx.save()
      // @ts-ignore
      ctx.letterSpacing = '0.08px'
      ctx.font = `${size / 3.5}px Arial`
      ctx.textAlign = 'center'
      ctx.globalAlpha = (() => {
        let globalAlpha = 0.15
        if (node.isDragging || node.isFocus || node.isSelected) globalAlpha = 1
        if (!isDragging.current && !isSelected.current) globalAlpha = 1
        if (isDragging.current && !node.isDragging) globalAlpha = 0.15
        return globalAlpha
      })()
      ctx.fillStyle = alpha(palette.text.primary, Math.log2(scale / 1.2))

      ctx.fillText(node.title ?? '', x, y + size * 1.2)
      ctx.restore()
    },
    [],
  )

  return (
    <Graph
      // @ts-ignore
      ref={graph}
      minZoom={1}
      maxZoom={8}
      autoPauseRedraw
      nodeRelSize={9}
      linkDirectionalParticleColor={() => palette.purple}
      linkDirectionalParticleWidth={() => 1.5}
      graphData={data}
      onBackgroundClick={handleBackgroundClick}
      onEngineStop={handleEngineStop}
      linkCanvasObject={renderLine}
      // @ts-ignore
      nodeCanvasObject={renderNode}
      // @ts-ignore
      onNodeDrag={handleNodeDrag}
      // @ts-ignore
      onNodeClick={handleNodeClick}
      // @ts-ignore
      onNodeDragEnd={handleNodeDragEnd}
    />
  )
})
